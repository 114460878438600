<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <div class="content">
                    <div class="item">
                        <el-button type="text" class="btn-red-script" icon="el-icon-delete" @click="toggleSelection()">删除</el-button>
                    </div>
                    <div class="script-search">
                        <el-input v-model="searchInput" size="small" placeholder="请输入搜索内容" @keyup.enter.native="changeSearch" >
                            <i class="el-icon-search el-input__icon search-btn" slot="suffix"
                               @click="changeSearch"></i>
                        </el-input>
                    </div>
                </div>
                <el-tab-pane label="脚本库"  name="first"> </el-tab-pane>
                <el-tab-pane label="草稿箱" name="second"></el-tab-pane>
                <ScriptModule ref="ScriptModule" v-if="activeName === 'first'"/>
                <DraftsModule ref="DraftsModule" v-else />
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import ScriptModule from '../../components/adminmodule/ScriptModule.vue'
    import DraftsModule from '../../components/adminmodule/DraftsModule.vue'
    export default {
        name: "ClassificationManage",
        components: {
            ScriptModule,
            DraftsModule,
        },
        data(){
            return{

                activeName: 'first',
                // activeName: 'first',
                searchInput:'',
                classifiesTable:[],
                multipleSelection: [],
                //分页
                classifiesPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                },
            }
        },
        created() {
            if(localStorage.getItem('activeName')){
                this.activeName = localStorage.getItem('activeName');
                localStorage.removeItem('activeName');
            }
        },
        methods:{
            handleClick() {
                this.searchInput = '';
                if(this.activeName === 'first'){
                    if (this.$refs.ScriptModule){
                        this.$refs.ScriptModule.getScriptList()
                    }
                }else {
                    if (this.$refs.DraftsModule){
                        this.$refs.DraftsModule.getDraftsScriptList();
                    }
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            changeSearch(){
                if(this.activeName === 'first'){
                    if (this.$refs.ScriptModule){
                        this.$refs.ScriptModule.getScriptList(this.searchInput);
                    }
                }else {
                    if (this.$refs.DraftsModule){
                        this.$refs.DraftsModule.getDraftsScriptList(this.searchInput);
                    }
                }
            },
            toggleSelection() {
                if(this.activeName === 'first'){
                    this.$refs.ScriptModule.dellData();
                }else {
                    this.$refs.DraftsModule.dellData();
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .admin-top{
        ::v-deep .el-tabs{
            width: 100%;
        }
        ::v-deep.el-tabs__nav-wrap::after{
            background-color: transparent;
        }
        ::v-deep.el-tabs__item.is-active {
            color: #574DED;
        }
        ::v-deep.el-tabs__item:hover {
            color: #1D33E1;
            cursor: pointer;
        }
        .content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 10px;

            .item{
                .btn-red-script{
                    display: inline-block;
                    color: #F90B32 ;
                    font-size: 14px;
                }
                .btn-red-script:hover,
                .btn-red-script:focus,
                .btn-red-script.current {
                    color: #dc0012;
                }
            }
            .script-search{
                width: 270px;
                height: 30px;
                background: #FFFFFF;
                border: 1px solid #594FEE;
                border-radius: 5px;
                ::v-deep.el-input--small{
                    .el-input__inner{
                        height: 30px;
                    }
                }
            }
        }
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium th{
        padding: 16px 4px;
    }

    ::v-deep.el-tabs__active-bar{
        background-color:transparent;
    }
    ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
</style>